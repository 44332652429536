html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* 移除横向滚动条 */
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.App {
    flex: 1;
    display: flex;
    flex-direction: column;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
}

img {
    max-width: 100%;
    height: auto;
}
